<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Add Facility</h1>
    </page-header>
    <page-body class>
      <h1>
        {{ translations.tcAddEditPrayerRequest }}
        <div class="change-link">{{ translations.tcNew }}</div>
      </h1>
      <section class="section-1 bg-block mb-4">
        <div class="body">
          <header class="toggle-header mb-3">
            <h2 class="font-style-1">{{ translations.tcDetails }}</h2>
            <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
          </header>
          <div v-if="Section1">
            <b-form>
              <div class="row">
                <div class="col-6">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcTitle }} *</label>
                    </div>
                    <div class="mb-3">
                      <b-form-input id="title" v-model="prayerRequest.title" class="flex-0" type="text"></b-form-input>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-6" v-if="showCalendars">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcCalendar }} *</label>
                    </div>
                    <div class="mb-3">
                      <b-form-select title="Church Country" v-model="prayerRequest.key" :options="optionCalendars"
                        class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcStartDate }} *</label>
                    </div>
                    <div class="g-row mb-0 mb-sm-3">
                      <div class="g-col-1 d-flex mb-6 mb-sm-0">
                        <b-form-datepicker id="startDate" v-model="prayerRequest.start" class="mb-2" :locale="prefCulture"
                          :min="min" :label-help="translations.tcCalHelp"
                          :label-no-date-selected="translations.tcDatePickerPlaceholder"
                          :placeholder="translations.tcDatePickerPlaceholder" :show-decade-nav="showDecadeNav"
                          :hide-header="hideHeader" @hidden="setEndDate">
                          <template #button-content style="padding: 12px">
                            <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                          </template>
                        </b-form-datepicker>
                      </div>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-lg-6 col-md-6">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcHowManyDays }} *</label>
                    </div>
                    <div class="g-row mb-0 mb-sm-3">
                      <div class="g-col-1 d-flex mb-3 mb-sm-0">
                        <b-form-input id="noOfDay" v-model="noOfDays" class="flex-0 noOfDays" type="number"
                          @change="setEndDate" max="60" min="1"></b-form-input>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </section>
      <section class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcRequest }} *</h2>
        </header>
        <div class="" v-if="translations.tcEditorPlaceHolder == editorOptions.placeholder">
          <quill-editor class="request-editor" v-model="prayerRequest.detail" ref="myQuillEditor"
            :options="editorOptions">
          </quill-editor>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
              {{ translations.tcSave }}
            </b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
              {{ translations.tcCancel }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch @selectionMade="applyFoundPerson($event)"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import { quillEditor } from 'vue-quill-editor'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import 'vue2-timepicker/dist/VueTimepicker.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'edit-prayer-request',
  mixins: [translationMixin],
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    return {
      Section1: true,
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      presetDate: null,
      errors: [],
      showCalendars: false,
      noOfDays: 1,
      prayerRequest: {
        key: '',
        detail: '',
        end: null,
        start: null,
        title: '',
        user: '',
      },
      editorOptions: { placeholder: '' },
      min: minDate,
    }
  },
  methods: {
    ...mapActions({
      addPrayerRequest: 'prayerCenter/addPrayerRequest',
      setLoadingStatus: 'menu/setLoadingStatus',
      updatePrayerRequest: 'prayerCenter/updatePrayerRequest',
    }),
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    setEndDate() {
      if (typeof this.prayerRequest.start !== 'string') {
        this.prayerRequest.start = this.prayerRequest.start.toISOString('en-US')
      }
      const dparts = this.prayerRequest.start.replace('T', '-').split('-')
      let addedDate = new Date(
        dparts[0] + '-' + (dparts[1].length < 2 ? '0' : '') + dparts[1] + '-' + (dparts[2].length < 2 ? '0' : '') + dparts[2] + 'T01:02:03'
      )
      let addedDays = parseInt(this.noOfDays) - 1
      let addedDateDay = addedDate.getDate()
      addedDate.setDate(addedDateDay + addedDays)
      this.prayerRequest.end = `${addedDate.getFullYear()}-${addedDate.getMonth() + 1}-${addedDate.getDate()}`
    },
    validate() {
      this.errors = []
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      if (this.prayerRequest.start == null) {
        this.errors.push(this.translations.tcInvalidStartDate)
      }
      if (this.prayerRequest.end == null) {
        this.errors.push(this.translations.tcInvalidEndDate)
      }
      if (this.prayerRequest.key === '') {
        this.errors.push(this.translations.tcPleaseSelectCalendar)
      }
      if (this.prayerRequest.title === '') {
        this.errors.push(this.translations.tcTitleIsEmpty)
      }
      if (this.prayerRequest.detail === '') {
        this.errors.push(this.translations.tcPrayerRequestDetailIsEmpty)
      }
      return this.errors.length <= 0
    },
    handleSaveClick() {
      if (!this.validate()) {
        this.$swal({
          icon: 'error',
          text: this.errors.join(', '),
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
        return
      }
      this.submitRequest(this.prayerRequest)
    },
    async submitRequest(data) {
      try {
        let params = {
          orgKey: this.orgKey,
          data: data,
        }
        this.setLoadingStatus(true)
        let response = false
        if (this.edit) {
          response = await this.updatePrayerRequest(params)
        } else {
          response = await this.addPrayerRequest(params)
        }
        this.setLoadingStatus(false)
        if (response == true) {
          this.$swal({
            icon: 'success',
            text: this.translations.tcRequestAddedSuccessfully,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
          this.$router.push({
            name: 'prayer-add-edit-request',
          })
          return
        }
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAddingRequest,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      } catch (error) {
        console.error(error)
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAddingRequest,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
    },
    handleCancelClick() {
      this.$router.push({
        name: 'prayer-add-edit-request',
      })
    },
    calcDateDiff(start, end) {
      start.setHours(0, 0, 0, 0)
      end.setHours(0, 0, 0, 0)
      let dit = end.getTime() - start.getTime()
      let did = dit / (1000 * 3600 * 24) + 1
      return did
    },
    async pageLoad() {
      const today = new Date()
      this.prayerRequest.start = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      this.prayerRequest.user = this.userId
      if (this.newRequestObject.prayerKey != null) {
        this.prayerRequest.key = this.newRequestObject.prayerKey
      } else {
        this.showCalendars = true
      }
      this.setEndDate()
      if (this.edit) {
        this.prayerRequest.start = this.newRequestObject.item.cid_start_date.substring(0, 10)
        this.prayerRequest.end = this.newRequestObject.item.cid_end_date.substring(0, 10)
        this.prayerRequest.detail = this.newRequestObject.item.cid_request_detail
        this.prayerRequest.title = this.newRequestObject.item.cid_request_title
        this.noOfDays = this.calcDateDiff(new Date(this.prayerRequest.start), new Date(this.prayerRequest.end))
      }
    },
    loadTranslations() {
      this.editorOptions.placeholder = this.translations.tcEditorPlaceHolder
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([this.getViewTranslations(), this.pageLoad()]).then(() => {
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      campKey: 'user/userCampKey',
      stateKey: 'user/userStateKey',
      internationalKey: 'user/internationalKey',
      newRequestObject: 'prayerCenter/newRequestObject',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
    }),
    edit() {
      return !!this.newRequestObject.item
    },
    orgKey() {
      return (
        { camp: this.campKey, country: this.stateKey, state: this.stateKey, headquarters: this.internationalKey }[
        this.newRequestObject.level
        ] || ''
      )
    },
    optionCalendars() {
      return this.newRequestObject.calendars
    },
  },
  components: {
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageHeader: pageHeader,
    quillEditor,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.request-editor {
  height: auto;
  min-height: 200px;
}

.request-editor::v-deep .ql-container {
  min-height: 200px;
}

input[type='number'] {
  height: auto;
  max-width: 85px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.page-body {
  padding: 80px 15px;
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
